import { RecordResult } from '../../components/Common/RecordResult';
import { IDeviceInfo } from '../../components/Report/utils/getDeviceInfo';
import { Action, OverlayAction } from "../../utils/figma";
import { IUrlTags } from "../Report/IAnswerMetaData";
import { BlockType } from "../Test";

export interface IBlockTestResponse {
	answerId: string;
	userAgent?: string | undefined;
	responseTime?: number;
	submittedAt: number;
	startedAt?: number | null;
	completedAt: number;
	duration?: number | null;
}

export interface IBlockRecordResponse {
	recordResult: RecordResult | null;
}

export interface IOpenQuestionResponse extends IBlockTestResponse {
	textValue: string;
}

export interface IChoiceResponse extends IBlockTestResponse {
	selectedOptions: { id: string; value: string }[];
}

export interface IScaleResponse extends IBlockTestResponse {
	selectedOption: string;
}

export interface IPreferenceSingleComparisonResponse extends IBlockTestResponse {
	selectedOptions: { id: string; value: string }[];
}

export interface IPreferencePairwiseComparisonResponse extends IBlockTestResponse {
	selectedOptions: { pairId: string; winner: string; option1: string; option2: string }[];
}

export interface IPreferenceResponse extends IBlockTestResponse {
	selectedOptions: IPreferenceSingleComparisonResponse["selectedOptions"] | IPreferencePairwiseComparisonResponse["selectedOptions"];
}

export interface IMatrixResponse extends IBlockTestResponse {
	selectedOptions: Record<string, string[]>;
}

export interface ICardSortResponse extends IBlockTestResponse {
	sorting: SortingCategory[];
}

export interface IAiChatResponse extends IBlockTestResponse {
	chatId: number;
	chat: any;
}

export interface ILiveTestingResponse extends IBlockTestResponse, IBlockRecordResponse {
	time: number;
	givenUp: boolean;
}

export interface IFigmaResponse extends IBlockTestResponse {
	givenUp: boolean;
	nodeEventData: INodeEventData[];
	path: string[];
	time: number;
	withVideo?: boolean;
	recordResult: RecordResult | null;
	reason?: string;
	devicePixelRatio?: number;
	size: CanvasSize;
	date: string;
}

export interface IAIResponse extends IBlockTestResponse {
}

export interface ILiveTestingResponse extends IBlockTestResponse {
	time: number;
	givenUp: boolean;
	recordResult: RecordResult | null;
}

export interface Size { width: number; height: number }
export interface CanvasSize {
	bodySize: Size,
	canvasDisplaySize: Size,
	canvasRealSize: Size
};

export interface IFirstClickResponse extends IBlockTestResponse {
	clickData: {
		top: number;
		left: number;
		responseTime: number;
	};
}

export interface IClickData {
	clickData: IBaseClick;
}

export interface IBaseClick {
	left: number;
	top: number;
}

export interface INodeEventData {
	clicks: IClick[];
	nodeId: string;
	timeSpent: number;
	overlays?: OverlayAction[];
}
export interface IClick {
	nearestScrollingFrameId?: string;
	nearestScrollingFrameMousePosition?: { x: number, y: number };
	nearestScrollingFrameOffset?: { x: number, y: number };
	targetNodeMousePosition?: { x: number, y: number };
	targetNodeId?: string;
	nodeId: string;
	timestamp: number;
	handled?: boolean;
	y: number;
	x: number;
	action?: Action;
	presentedNodeId: string;
}

export type SortingCard = {
	id: string,
	value: string,
	image: string | null | undefined,
	description: string | null | undefined,
}

export type SortingCategory = {
	id: string;
	value: string | undefined;
	isCustom: boolean | undefined;
	cards: SortingCard[];
}

export type CardSortGroup = {
	id: string;
	name: string;
	categories: string[];
}

export function isOpenQuestionsResponse(response: any): response is IOpenQuestionResponse {
	return "textValue" in response;
}

export function isChoiceResponse(response: any): response is IChoiceResponse {
	if (
		"selectedOptions" in response &&
		Array.isArray(response.selectedOptions) &&
		response.selectedOptions.every((option: any) => typeof option.id === "string" && typeof option.value === "string")
	) {
		return true;
	}
	return false;
}

export function isScaleResponse(response: any): response is IScaleResponse {
	return "selectedOption" in response;
}

export function isPreferenceSingleComparison(response: any): response is IPreferenceSingleComparisonResponse {
	if (!("selectedOptions" in response) || !Array.isArray(response.selectedOptions)) {
		return false;
	}
	return response.selectedOptions.every((option: any) => 
		typeof option === "object" &&
		option !== null &&
		typeof option.id === "string" && 
		typeof option.replyValue === "string"
	);
}

export function isPreferencePairwiseComparison(response: any): response is IPreferencePairwiseComparisonResponse {
	if (!("selectedOptions" in response) || !Array.isArray(response.selectedOptions)) {
		return false;
	}
	return response.selectedOptions.every((option: any) => 
		typeof option === "object" &&
		option !== null &&
		"pairId" in option &&
		typeof option.pairId === "string" && 
		"winner" in option &&
		typeof option.winner === "string" && 
		"option1" in option &&
		typeof option.option1 === "string" && 
		"option2" in option &&
		typeof option.option2 === "string"
	);
}

export function isPreferenceResponse(response: any): response is IPreferenceResponse {
	return isPreferenceSingleComparison(response) || isPreferencePairwiseComparison(response);
}

export function isFigmaResponse(response: any): response is IFigmaResponse {
	return "path" in response;
}

export function isMatrixResponse(response: any): response is IMatrixResponse {
	if (
		"selectedOptions" in response &&
		typeof response.selectedOptions === "object" &&
		response.selectedOptions !== null &&
		Object.values(response.selectedOptions).every((value) => Array.isArray(value) && value.every((element) => typeof element === "string"))
	) {
		return true;
	}
	return false;
}

export function isFirstClickResponse(response: any): response is IFirstClickResponse {
	return "clickData" in response;
}

export function isCardSortResponse(response: any): response is ICardSortResponse {
	return "sorting" in response;
}

export function isAIResponse(response: any): response is IAiChatResponse {
	return "chatId" in response;
}

export function isLiveTestingResponse(response: any): response is ILiveTestingResponse {
	return "givenUp" in response;
}

export function isOpenQuestionsResponseArray(response: any[]): response is IOpenQuestionResponse[] {
	return response.every(isOpenQuestionsResponse);
}

export function isChoiceResponseArray(response: any[]): response is IChoiceResponse[] {
	return response.every(isChoiceResponse);
}

export function isScaleResponseArray(response: any[]): response is IScaleResponse[] {
	return response.every(isScaleResponse);
}

export function isPreferenceResponseArray(response: any[]): response is IPreferenceResponse[] {
	return response.every(isPreferenceResponse);
}

export function isFigmaResponseArray(response: any[]): response is IFigmaResponse[] {
	return response.every(isFigmaResponse);
}

export function isMatrixResponseArray(response: any[]): response is IMatrixResponse[] {
	return response.every(isMatrixResponse);
}

export function isFirstClickResponseArray(response: any[]): response is IFirstClickResponse[] {
	return response.every(isFirstClickResponse);
}

export function isCardSortResponseArray(response: any[]): response is ICardSortResponse[] {
	return response.every(isCardSortResponse);
}

export function isAIResponseArray(response: any[]): response is IAiChatResponse[] {
	return response.every(isAIResponse);
}

export function isLiveTestingResponseArray(response: any[]): response is ILiveTestingResponse[] {
	return response.every(isLiveTestingResponse);
}

export interface IIndividualTestResponse {
	answerId: string;
	urlTags: IUrlTags | undefined;
	userAgent: string | undefined;
	deviceInfo: IDeviceInfo | undefined;
	blockAnswers: {
		data: IBlockTestResponse;
		blockId: string;
		blockType:
		| BlockType.openquestion
		| BlockType.choice
		| BlockType.firstclick
		| BlockType.figma
		| BlockType.preference
		| BlockType.cardsort
		| BlockType.matrix
		| BlockType.scale
		| BlockType.ai
		| BlockType.livetesting,
	}[];
}
