import clsx from 'clsx';
import React, { useContext, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IMessageTag } from '../../../../Common/schema/Chats/UpdateChatMessageDTO';
import { BTextAccent } from '../../../Common/Text';
import { MessageTag } from '../Chat/MessageTag';
import { useTags } from '../useTags';
import { TestBlockReportContext } from '../../ReportContext';
import { useChatReport } from '../useChatReport';
import { IconDropdown, IconTag, IconTrash } from '../../../../icons';
import { ListWithSeparators } from '../../../Common/Containers/ListWithSeparators';
import { CollapsibleContainer } from '../../../Common/Containers/CollapsibleContainer';
import Loader from '../../../Loader';
import { calculatePercentages } from '../../utils/calculatePercentages/calculatePercentages';

const minVisibleCount = 5;

export function AiChatTags(props: {
	filterTags: Record<number, boolean>;
	chats: ReturnType<typeof useChatReport>['taggedChats'];
	className?: string;
	onTagSelect?: (tag: IMessageTag) => void;
}) {
	const { t } = useTranslation();
	const reportContext = useContext(TestBlockReportContext);
	const tags = useTags({ testId: reportContext?.testId || '', blockId: reportContext?.block.blockId || '' });
	const chatsCount = props.chats?.length || 0;

	const [isCollapsed, setIsCollapsed] = useState(true);

	function getPercentOfChatsCount(tag: IMessageTag) {
		if (!chatsCount) return { count: 0, percent: 0 };
		const tagChatsCount = Object.keys(tag.chats).length;
		
		const percentages = calculatePercentages({ [tag.id]: tagChatsCount }, chatsCount);
		
		return {
			count: tagChatsCount,
			percent: percentages[tag.id]
		};
	}

	const tagStats = useMemo(() => {
		if (!tags.list.data) return [];
		
		return tags.list.data
			.filter(t => !props.filterTags[t.id])
			.map(t => ({ tag: t, stats: getPercentOfChatsCount(t) }))
			.sort((a, b) => b.stats.count - a.stats.count);
	}, [tags.list.data, props.filterTags, chatsCount]);

	const tagsCount = tagStats.length;

	return (
    <div className={clsx("ai-chat-tags", props.className)}>
        {tags.list.isPending && <Loader />}
        {!tags.list.isPending && !tagsCount && (
          <div className="text-gray-600 w-full flex flex-col items-center justify-center text-base my-12 gap-4">
            <IconTag className="w-10 h-10 shrink-0 mr-2 fill-current text-gray-500" />
            {t("Tag some messages for analytics.")}
          </div>
        )}

        {!!tagsCount && (
          <CollapsibleContainer
            isCollapsed={isCollapsed}
            minH={minVisibleCount * 50}
            maxH={tagsCount * 50}
          >
            <ListWithSeparators className="ai-chat-tags-list w-full">
              {tagStats?.map((tagModel) => {
                return (
                  <div
                    key={tagModel.tag.id}
                    className="ai-chat-tags-list__item group/tag-list-item flex flex-row items-center justify-between"
                  >
                    <div className='flex flex-row gap-2'>
                      <MessageTag
                        className={clsx("cursor-pointer", {
                          "animate-pulse":
                            tags.untagMessage.variables?.tagId === tagModel.tag.id && tags.untagMessage.isPending,
                        })}
                        colorClassName={tags.getTagStyle(tagModel.tag.color)}
                        withContextMenu={!reportContext?.sharedByLink}
                        onClick={() => props.onTagSelect?.(tagModel.tag)}
                        onDeleteClick={() => tags.untagMessage.mutate({ tagId: tagModel.tag.id, request: {} })}
                        listMode={true}
                      >
                        <span>{tagModel.tag.name}</span>
                      </MessageTag>
                      <IconTrash className='w-5 h-5 fill-current text-gray-700 hover:text-red-600 transition-all duration-150 ease-in-out cursor-pointer opacity-0 invisible group-hover/tag-list-item:opacity-100 group-hover/tag-list-item:visible' onClick={
                        () => tags.untagMessage.mutate({ tagId: tagModel.tag.id, request: {} })
                      } />
                    </div>
                    <div className="font-semibold">
                      {tagModel.stats.count} <span className="text-gray-500">({tagModel.stats.percent}%)</span>
                    </div>
                  </div>
                );
              })}
            </ListWithSeparators>
          </CollapsibleContainer>
        )}

        {tagsCount > minVisibleCount && (
          <a className="text-gray-600 flex flex-row items-center gap-2" onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed ? t("Show all topics ({{count}})", { count: tagsCount }) : t("Show less")}
            <IconDropdown
              className={clsx("w-4 h-4 transition-transform duration-200 ease-in-out", { "rotate-180": !isCollapsed })}
            />
          </a>
        )}
    </div>
  );
}

