import { authorizedClient } from "../http/Client";
import i18n from "../i18n/config";
import { IPGHiringOrder } from "../models/Hiring/IPGHiringOrder";
import { ITest } from "../models/Test";

export class CreateTestRequest {
  constructor(
    public name: string = i18n.t('New test'),
    public sourceTestId: string | null = null,
    public splitGroupId: number | null = null,
    public folderId: string | null = null
  ) {}
}

export class TestsAPI {
  static baseURL = '/api/v1/tests';

  public static async getHiringOrderByTestId(testId: string): Promise<IPGHiringOrder> {
    const response = await authorizedClient.get(`${this.baseURL}/${testId}/hiring_order`);

    return await response.data;
  }

  public static async createTest(params: CreateTestRequest, signal?: AbortSignal): Promise<ITest> {
    const response = await authorizedClient.post(`${this.baseURL}`, params, { signal });

    return await response.data;
  }
}
