import React, { Suspense, useState, useEffect, useReducer } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import lodash from "lodash";

import "./tailwind.generated.css";
import UserApp from "./UserApp";
import SignIn from "./components/UserAccount/Pages/Login/SignIn";
// import Test from "./components/Respondent/Pages/Test/Test";
const Test = React.lazy(() => import("./components/Respondent/Pages/Test/Test"));
import Report from "./components/Report/Report";
import Loader from "./components/Loader";
import AuthByToken from "./components/AuthByToken";
import FigmaTmp from "./components/FigmaTmp";
import Invite from "./components/Invite";
import Toast from "./components/Toast";
import { PageNotFound } from "./components/Common";

import appContext, { DefaultAppState } from "./AppContext";
import reducer from "./reducer";

import { useAuth } from "./hooks";

import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";

import { usePageViews } from "./utils/gtm";
import { getRedirectUrl } from "./utils";

const TrackPageView = ({ component }) => {
	usePageViews();
	return component;
};

const SplitGroupDistribution = React.lazy(() =>
	import(
		"./components/Respondent/Pages/SplitGroupDistribution/SplitGroupDistribution"
	)
);
const queryClient = new QueryClient();

const App = () => {
	const [state, dispatch] = useReducer(reducer, DefaultAppState);
	const [isLoading, setIsLoading] = useState(true);
	const [isAuthorized, setIsAuthorized] = useState(null);
	const redirectUrl = getRedirectUrl();

	const userAuth = useAuth();

	useEffect(() => {
		if (
			import.meta.env.MODE === "development" &&
			lodash.startsWith(window.location.pathname, "/figmaTmp")
		) {
			setIsAuthorized(false);
			setIsLoading(false);
			return undefined;
		}

		if (userAuth) {
			setIsAuthorized(true);
			setIsLoading(false);
		}

		if (userAuth === false) {
			setIsAuthorized(false);
			setIsLoading(false);
		}
	}, [userAuth]);

	if (isLoading) {
		return <Loader />;
	}

	return (
		!isLoading && (
			<Router>
				<appContext.Provider
					displayName="Pathway Context"
					value={{ state, dispatch }}
				>
					<Toast />
					<QueryClientProvider client={queryClient}>
						<Switch>
							{import.meta.env.MODE === "development" && (
								<Route exact path="/authByToken">
									<AuthByToken />
								</Route>
							)}
							{import.meta.env.MODE === "development" && (
								<Route exact path="/figmaTmp">
									<FigmaTmp />
								</Route>
							)}
							<Route exact path="/invite/:inviteCode">
								{isAuthorized ? (
									<TrackPageView component={<Invite />} />
								) : (
									<Redirect
										to={`/login?redirectUrl=${window.location.pathname.split("?")[0]}`}
									/>
								)}
							</Route>
							<Route exact path="/login">
								{isAuthorized ? (
									<Redirect to={redirectUrl || "/"} />
								) : (
									<TrackPageView component={<SignIn key="sign-in" />} />
								)}
							</Route>
							<Route exact path="/login/with-email">
								{isAuthorized ? (
									<Redirect to={redirectUrl || "/"} />
								) : (
									<TrackPageView
										component={<SignIn key="sign-in-with-email" withEmail />}
									/>
								)}
							</Route>
							<Route exact path="/:testId/preview">
								<TrackPageView component={<Test isPreview={true} />} />
							</Route>
							<Route exact path="/:testId/report">
								<TrackPageView component={<Report sharedByLink={true} />} />
							</Route>
							<Route exact path="/split/:splitGroupCode">
								<Suspense fallback={<Loader />}>
									<SplitGroupDistribution ifNotFoundComponent={<PageNotFound />} />
								</Suspense>
							</Route>
							{isAuthorized && <UserApp />}
							<Route exact path="/settings">
								<Redirect to="/login" />
							</Route>
							<Route exact path="/:testId">
								<TrackPageView component={<Test isPreview={false} />} />
							</Route>
							<Route exact path="/">
								<Redirect to="/login" />
							</Route>
							<Route path="*">
								<PageNotFound />
							</Route>
						</Switch>
					</QueryClientProvider>
				</appContext.Provider>
			</Router>
		)
	);
};

export default App;
