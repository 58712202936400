import React, { useState, useRef } from "react";
import clsx from "clsx";
import "../tailwind.generated.css";

import {
	useFloating,
	autoUpdate,
	offset,
	flip,
	shift,
	useHover,
	useFocus,
	useDismiss,
	useRole,
	useInteractions,
	FloatingPortal,
	FloatingArrow,
	arrow,
	Placement,
} from "@floating-ui/react";

interface TooltipProps {
	content: string | JSX.Element | null;
	anchorClassName?: string;
	children: JSX.Element;
	showOnlyIf?: boolean;
	position?: Placement | "cursor";
}

function Tooltip({
	content,
	anchorClassName,
	children,
	showOnlyIf = true,
	position = "top",
}: TooltipProps) {
	const [isVisible, setIsVisible] = useState(false);
	const arrowRef = useRef(null);
	const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
	const isCursorMode = position === "cursor";
	
	// Use a valid placement for floating-ui
	const floatingPlacement: Placement = isCursorMode ? "top" : position as Placement;

	const { x, y, strategy, refs, context } = useFloating({
		placement: floatingPlacement,
		open: isVisible,
		onOpenChange: (openState) => {
			if (showOnlyIf) {
				setIsVisible(openState);
			}
		},
		middleware: [
			offset(10),
			arrow({ element: arrowRef }),
			...(isCursorMode ? [] : [flip(), shift()]),
		],
		whileElementsMounted: autoUpdate,
	});

	const hover = useHover(context, { move: false });
	const focus = useFocus(context);
	const dismiss = useDismiss(context);
	const role = useRole(context, { role: "tooltip" });

	const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

	// Handle mouse move to track cursor position
	const handleMouseMove = (event: React.MouseEvent) => {
		if (isCursorMode) {
			setCursorPosition({ x: event.clientX, y: event.clientY - 16 });
		}
	};

	if (!content) {
		return children;
	}

	return (
		<div
			className={clsx("cursor-pointer tooltip__anchor", anchorClassName)}
			ref={refs.setReference}
			{...getReferenceProps()}
			onMouseMove={handleMouseMove}
		>
			{children}
			{isVisible && showOnlyIf && (
				<FloatingPortal>
					<div
						ref={refs.setFloating}
						style={{
							position: strategy,
							top: isCursorMode ? cursorPosition.y : y ?? 0,
							left: isCursorMode ? cursorPosition.x : x ?? 0,
							width: "max-content",
							zIndex: 9999,
							...(isCursorMode && {
								transform: 'translateX(-50%) translateY(-100%)'
							})
						}}
						{...getFloatingProps()}
						className="bg-gray-900 text-white rounded-md p-2 text-base tooltip tooltip__content z-999"
					>
						{!isCursorMode && <FloatingArrow ref={arrowRef} context={context} tipRadius={4} />}
						{content}
					</div>
				</FloatingPortal>
			)}
		</div>
	);
}

export default Tooltip;
