import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { makeAuthorizedRequest } from '../../../http/Client';
import { useAuth, useSendNotification } from '../../../hooks';
import { getUserData } from '../../../actions';
import { useContext, useEffect } from 'react';
import { useLocalStorage } from '../../Common/Hooks/useLocalStorage';
import AppContext from '../../../AppContext';
import { IUser } from '../../../models/User';
import { IAccount, ITariffPlan } from '../../../models/Account/IAccount';
import { IProject } from '../../../models/Project/IProject';
import { useTranslation } from 'react-i18next';

export interface ICreditsOrderRequest {
  quantity: number;
  paymentMethodCode?: 'tinkoff_rf';
  paymentMethodId?: number;
}

export interface ISubscriptionOrderRequest {
  tariffId: number;
  paymentMethodCode?: 'tinkoff_rf';
  paymentMethodId?: number;
}

export interface IOrderResponse {
  result?: 'error';
  status: 'success' | 'confirmation_required',
  payment_url?: string
}

export interface ICurrentUserData {
  user: IUser;
  account: IAccount;
  currentProject: IProject | null;
  credits: any;
  subscription: {
    plan: string;
    startedAt: string;
    expiresAt: string;
    usage: {
      tests?: {
        usage: number;
        limit: number;
        isLimitReached: boolean;
      };
      responses?: {
        usage: number;
        limit: number;
        isLimitReached: boolean;
      };
      users?: {
        usage: number;
        limit: number;
        isLimitReached: boolean;
      };
    },
  },
  usage: {
    tests: number;
    responses: number;
    users: number;
    exceededAnswersLimit: boolean;
    exceededTestsLimit: boolean;
  };
}

export function useAccount() {
  const setNotification = useSendNotification();
  const user = useAuth();
  const queryClient = useQueryClient()
  const localStorage = useLocalStorage();
  const context = useContext(AppContext);

  const { t } = useTranslation();

  const currentUser = useQuery<ICurrentUserData>({
    queryKey: ['user'],
    queryFn: () => getUserData(user).then(d => {
      Object.assign(d.payload.usage, getUsageWarnings(d.payload.account.currentSubscription.tariff, d.payload.usage));
      context.dispatch(d);
      return d.payload;
    }).catch((e) => {
      if (e.cause?.status === 504) {
        setNotification("error", t("Oops, something went wrong. Maybe you`re offline?"));
      } else {
        setNotification("error", t("Oops, something went wrong."));
      }
      console.error(e);
    }),
    enabled: !!user,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: 3,
  });

  const cancelSubscription = useMutation({
    mutationFn: () => makeAuthorizedRequest('/api/v1/account/subscription', 'PUT', { isRecurring: false }).then(toJson),
    onSuccess: () => { queryClient.invalidateQueries({ queryKey: ['user'] }) },
    onError: (error) => {
      console.log(error);
      setNotification('error', t('An error occurred while cancelling subscription. Please try again later.' as any));
    },
  });

  const resumeSubscription = useMutation({
    mutationFn: () => makeAuthorizedRequest('/api/v1/account/subscription', 'PUT', { isRecurring: true }).then(toJson),
    onSuccess: () => { queryClient.invalidateQueries({ queryKey: ['user'] }) },
    onError: (error) => {
      console.log(error);
      setNotification('error', t('An error occurred while resuming subscription. Please try again later.' as any));
    },
  });

  const upgradeSubscription = useMutation<{ id: number }, Error, ISubscriptionOrderRequest>({
    mutationFn: async (request) => {
      const orderRequest = { productCode: 'subscription', ...request };
      localStorage.setItem('last_order_request', orderRequest);
      const response = await makeAuthorizedRequest('/api/v1/orders', 'POST', orderRequest).then(toJson);
      if (response.result === 'error') {
        setNotification('error', t('An error occurred while upgrading your subscription. Please try again later.' as any));
      }
      return response;
    },
    onError: (error) => {
      console.log(error);
      setNotification('error', t('An error occurred while upgrading your subscription. Please try again later.' as any));
    },
  });

  const buyCredits = useMutation<{ id: number }, Error, ICreditsOrderRequest>({
    mutationFn: (request) => {
      const orderRequest = { productCode: 'credits', ...request };
      localStorage.setItem('last_order_request', orderRequest);
      return makeAuthorizedRequest('/api/v1/orders', 'POST', orderRequest).then(toJson);
    },
    onError: (error) => {
      console.log(error);
      setNotification('error', t('An error occurred while purchasing credits. Please try again later.' as any));
    },
  });

  const addPaymentMethod = useMutation<{ id: number }, Error>({
    mutationFn: () => {
      const request = { productCode: 'add_payment_method', paymentMethodCode: 'tinkoff_rf' };
      localStorage.setItem('last_order_request', request);
      console.log(request);
      return makeAuthorizedRequest('/api/v1/orders', 'POST', request).then(toJson);
    },
    onError: (error) => {
      console.log(error);
      setNotification('error', t('An error occurred while adding payment method. Please try again later.' as any));
    }
  });

  const capturePayment = useMutation<IOrderResponse, Error, number>({
    mutationFn: (paymentId: number) => makeAuthorizedRequest(`/api/v1/orders/${paymentId}/capture`, 'POST').then(toJson),
    onError: (error) => {
      console.log(error);
      setNotification('error', t('An error occurred while processing the payment. Please, try again later.' as any));
    },
    onSuccess: (capture) => {
      if (capture.result === 'error') {
        setNotification('error', t('An error occurred while processing the payment. Please, try again later.' as any));
        return;
      }

      if (capture.status === 'success') {
        queryClient.invalidateQueries({ queryKey: ['user'] });
      }
      if (capture.status === 'confirmation_required') {
        window.open(capture.payment_url, '_blank');
      }
    }
  });

  const removePaymentMethod = useMutation({
    mutationFn: (paymentMethodId: number) => makeAuthorizedRequest(`/api/v1/account/payment_methods/${paymentMethodId}`, 'DELETE').then(toJson),
    onSuccess: (response) => {
      if (response.result === 'error') {
        throw response;
      }
      queryClient.invalidateQueries({ queryKey: ['user'] });
      setNotification('warning', t('Payment method successfully deleted.' as any));
    },
    onError: (error) => {
      console.log(error);
      setNotification('error', t('An error occurred while deleting payment method. Please try again later.' as any));
    },
  });

  const managePaymentMethod = useMutation({
    mutationFn: (paymentMethodId: number) => makeAuthorizedRequest(`/api/v1/account/payment_methods/${paymentMethodId}/management_link`).then(toJson) as Promise<{ url: string }>,
  });


  return {
    cancelSubscription,
    resumeSubscription,
    upgradeSubscription,
    buyCredits,
    capturePayment,
    currentUser,
    addPaymentMethod,
    removePaymentMethod,
    managePaymentMethod
  }
}

function toJson(response: any) {
  return response.json();
}

export function getUsageWarnings(tariff: ITariffPlan, usage: { tests: number; responses: number; users: number; }) {
  return {
    exceededAnswersLimit: (tariff.responsesLimit > 0)
      ? usage.responses >= tariff.responsesLimit
      : false,
    exceededTestsLimit: (tariff.testsLimit > 0)
      ? usage.tests >= tariff.testsLimit
      : false,
  };
}