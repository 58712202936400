import { IconPointerDesktop } from "../../../../../icons";
import React, { ForwardedRef } from "react";

interface PointerProps {
  isMobile: boolean;
}

export default React.forwardRef(function Pointer({ isMobile }: PointerProps, ref: ForwardedRef<any>) {
  if (isMobile) {
    return (
      <div
        className="video-replayer__pointer w-6 h-6 rounded-full bg-red-500 -m-3 absolute transition-all ease-linear duration-[10ms] opacity-0"
        id="pointer"
        ref={ref}
      />
    )
  }

  return (
    <div className='video-replayer__pointer w-8 h-8 -m-4 absolute opacity-0'
      id="pointer"
      ref={ref}
    >
      <IconPointerDesktop
        className="video-replayer__pointer w-full h-full"
      />
    </div>
  )
});