import { makeAuthorizedRequest, makeRequest } from "../http/Client";
import { IActionResult } from "../AppContext";
import { pushToGTM } from "../utils/gtm";
import { CreateTestRequest, TestsAPI } from "./TestsAPI";
import { ITest } from "../models/Test";


export class TestsActions {
	public static async banTester(testId: string, testerId: string) {
		const testResponse = await makeAuthorizedRequest(`/api/v1/tests/${testId}/testers/${testerId}/block`, "POST");

		if (testResponse.ok) {
			return {
				type: "BAN_TESTER",
				payload: {
					data: {
						testId,
						testerId,
					},
				},
			};
		}

		throw new Error("http_error");
	}
	public static async approveTester(testId: string, testerId: string) {
		const testResponse = await makeAuthorizedRequest(`/api/v1/tests/${testId}/testers/${testerId}/approve`, "POST");

		if (testResponse.ok) {
			return {
				type: "APPROVE_TESTER",
				payload: {
					data: {
						testId,
						testerId,
					},
				},
			};
		}

		throw new Error("http_error");
	}
	public static async getTesters(testId: string, sharingToken: string) {
		const testResponse = await makeAuthorizedRequest(`/api/v1/tests/${testId}/testers?sharingToken=${sharingToken}`);

		if (testResponse.ok) {
			return {
				type: "GET_TESTERS",
				payload: {
					data: {
						[testId]: await testResponse.json(),
					},
				},
			};
		}

		throw new Error("http_error");
	}

	public static async getTest(testId: string) {
		const testResponse = await makeAuthorizedRequest(`/api/v1/tests/${testId}`);

		if (testResponse.ok) {
			return {
				type: "GET_TEST",
				payload: {
					testId: testId,
					data: await testResponse.json(),
				},
			};
		}

		throw new Error("http_error", { cause: testResponse });
	}

	public static async getTestWithSharingToken(testId: string, sharingToken: string) {
		const testResponse = await makeRequest(`/api/v1/tests/${testId}?sharingToken=${sharingToken}`);

		if (testResponse.ok) {
			return {
				type: "GET_TEST",
				payload: {
					testId: testId,
					data: await testResponse.json(),
				},
			};
		}

		throw new Error("http_error");
	}

	public static async getTestResponsesWithSharingToken(testId: string, sharingToken: string, lastCreatedAt: string) {
		const testResponse = await makeRequest(
			`/api/v1/tests/${testId}/answers?sharingToken=${sharingToken}&lastCreatedAt=${lastCreatedAt || ""}`
		);

		if (testResponse.ok) {
			return await testResponse.json();
		}

		throw new Error("http_error", { cause: testResponse });
	}

	public static async createTest(requestParams: CreateTestRequest, signal?: AbortSignal): Promise<{action: IActionResult<Record<string, ITest>>, test: ITest}> {
		try {
			const test = await TestsAPI.createTest(requestParams, signal);

			pushToGTM({
				event: "Test created",
				userId: test.userId,
				projectId: test.projectId,
			});

			return {
				action: {
					type: "CREATE_TEST",
					payload: {
						data: { [test.id!]: test as ITest },
					},
				},
				test,
			};
		} catch (error) {
			console.error('Failed to create test:', error);
			throw new Error("http_error", { cause: error });
		}
	}

	public static async listTests(folderId: string, signal?: AbortSignal) {
		const testsResponse = await makeAuthorizedRequest("/api/user/getTests", "POST", { folderId }, signal);

		if (testsResponse.ok) {
			return {
				type: "GET_TESTS",
				payload: {
					tests: await testsResponse.json(),
				},
			};
		}
		throw new Error("http_error");
	}
}
