import React from "react";
import "../../tailwind.generated.css";

import { IconTag } from "../../icons";

const UrlTag = ({ tag, value }: { tag: string; value: string }) => {
  return (
    <div className="url-tag flex items-center justify-center rounded-full px-2 py-1 border-2 border-gray-300">
      <IconTag
        width={24}
        height={24}
        className="url-tag__icon fill-current text-gray-800 mr-1"
      />
      <div className="url-tag__name mr-1 text-gray-700">{tag}</div>
      <div className="url-tag__value mr-1 text-gray-800">{value}</div>
    </div>
  );
};

export default UrlTag;
