import React from "react";
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import './i18n/config';
import App from "./App";
import TagManager from './components/TagManager';
import * as serviceWorker from "./serviceWorker";

import * as Sentry from "@sentry/react";
import { SENTRY_RELEASE, SENTRY_TRACES_SAMPLE_RATE } from './config';

window.addEventListener('vite:preloadError', () => {
  window.location.reload();
});

if (SENTRY_RELEASE && window.pwconfig?.sentry?.dsn) {
  Sentry.init({
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracePropagationTargets: ["localhost", /^https:\/\/app\.pthwy\.design/],
    // Performance Monitoring
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE ? Number(SENTRY_TRACES_SAMPLE_RATE) : 1, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    release: SENTRY_RELEASE,
    ...window.pwconfig.sentry,
  });
}
else {
  console.warn('Sentry is not initialized');
}

const container = document.getElementById('root');
const root = createRoot(container!);

const app = (
  <HelmetProvider>
    <TagManager />
    <App />
  </HelmetProvider>
)

// console.log("isDev", import.meta.env.DEV);

root.render(
  import.meta.env.NODE_ENV === "development" ? (
    <React.StrictMode>
      {app}
    </React.StrictMode>
  ) : (
    app
  )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
