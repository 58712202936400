import React from "react";
import {
  IconOpenQuestion,
  IconChoice,
  IconFirstClick,
  IconContext,
  IconFigma,
  IconPreference,
  IconFiveSeconds,
  IconCardSort,
  IconMatrix,
  IconScale,
  IconAIBlock,
  IconLiveTesting
} from "../../icons";
import { BlockType } from "../../models/Test";


interface ITestBlockThumb {
  name: string;
  icon: React.ReactElement;
  description: string;
  getIcon(props: any): React.ReactElement;
}

export const blockTypeMap: Record<BlockType, ITestBlockThumb> = {
  scale: {
    name: "Scale",
    icon: <IconScale />,
    description: "Ask participants to rate something on a scale",
    getIcon,
  },
  matrix: {
    name: "Matrix",
    icon: <IconMatrix />,
    description:
      "Ask several multiple-choice questions presented in a grid of rows and columns",
    getIcon,
  },
  cardsort: {
    name: "Card sort",
    icon: <IconCardSort />,
    description: "See how users group ideas",
    getIcon,
  },
  choice: {
    name: "Choice",
    icon: <IconChoice />,
    description: "Ask a question with multiple answer options",
    getIcon,
  },
  context: {
    name: "Context",
    icon: <IconContext />,
    description: "Provide some context during the test",
    getIcon,
  },
  figma: {
    name: "Figma",
    icon: <IconFigma />,
    description: "Test your Figma prototype",
    getIcon,
  },
  firstclick: {
    name: "First click",
    icon: <IconFirstClick />,
    description: "Ask to click on the interface in order to complete a task",
    getIcon,
  },
  fiveseconds: {
    name: "Five seconds",
    icon: <IconFiveSeconds />,
    description: "Show an image for a limited amount of time",
    getIcon,
  },
  openquestion: {
    name: "Open question",
    icon: <IconOpenQuestion />,
    description: "Collect plain text replies",
    getIcon,
  },
  preference: {
    name: "Preference",
    icon: <IconPreference />,
    description: "Compare 2 or more images",
    getIcon,
  },
  ai: {
    name: "AI question",
    icon: <IconAIBlock />,
    description: "Use AI to ask reach questions with follow-ups",
    getIcon,
  },
  livetesting: {
    name: "Live website",
    icon: <IconLiveTesting />,
    description: "Test any websites",
    getIcon,
  },
};

function getIcon(this: any, props: any) {
  return React.cloneElement(this.icon, props);
}

export default blockTypeMap;