import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import "../../tailwind.generated.css";

import { IChoiceResponse } from "../../models/Response";
import { ChoiceBlock } from "../../models/Test";

import { TestBlockReportContext } from "./ReportContext";
import ReportBlockHeader, { ReportHeaderMedia } from "./ReportBlockHeader";

type ChoiceReportProps = {
	responses: IChoiceResponse[];
};

export default function ChoiceReport(props: ChoiceReportProps) {
	const { t } = useTranslation();

	const reportContext = useContext(TestBlockReportContext);
	const block = reportContext?.block as ChoiceBlock;
	const { mediaType, image, text, other: withOther, video, replies } = block;

	const replyMap = useMemo(() => {
		const map: { [id: string]: string } = {};
		replies.forEach((reply) => {
			map[reply.id] = reply.replyValue;
		});

		return map;
	}, [replies]);

	const findReplyNameById = (id: string) => {
		if (id === "other") {
			return t("Other");
		}

		return replyMap[id] || "";
	};

	const data = useMemo(() => {
		const localData: { [key: string]: number } = {};

		// Initialize data structure
		[...replies, ...(withOther ? [{ id: "other" }] : [])].forEach((reply) => {
			localData[reply.id] = 0;
		});

		// Populate data using a single loop
		props.responses.forEach((response) => {
			response.selectedOptions.forEach((option) => {
				if (localData.hasOwnProperty(option.id)) {
					localData[option.id]++;
				}
			});
		});

		return localData;
	}, [replies, props.responses, withOther, replyMap]);

	const responsesWithOther = useMemo(() => {
		return props.responses.filter((response) => {
			return response.selectedOptions.some((option) => option.id === "other");
		});
	}, [props.responses]);

	return (
		<div className="flex flex-col gap-4">
			<div className="choice-report__question">
				<span className="block captionNoMargin">{t("Question")}</span>
				<ReportBlockHeader questionText={text}>
					<ReportHeaderMedia image={image} video={video} />
				</ReportBlockHeader>
			</div>
			{Object.keys(data).map((key) => (
				<div key={key} className="flex flex-col my-1">
					<div className="h-1 bg-gray-300 rounded-full w-full relative">
						<div
							className="h-1 rounded-full bg-[#F58E62]"
							style={{
								width: `${(data[key] / props.responses.length) * 100}%`,
							}}
						></div>
					</div>
					<div className="flex justify-between w-full items-center mt-2">
						<div>{findReplyNameById(key)}</div>
						<div className="font-medium">
							{Math.round((data[key] / props.responses.length) * 100) + "%"}
							<span className="font-normal text-gray-600 text-xs">&nbsp;({data[key]})</span>
						</div>
					</div>
				</div>
			))}
			{withOther && responsesWithOther.length > 0 && (
				<div>
					<div className="text-base font-medium text-gray-600">{t("Other responses:")}</div>
					<div className="flex flex-col items-start flex-wrap justify-start my-2">
						{responsesWithOther.map((response, index) => (
							<div key={`response-${index}`} className="border-gray-300 rounded-2xl border-1 px-2 py-1 mr-1 my-1">
								{response.selectedOptions.find((option) => option.id === "other")?.value}
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
};
