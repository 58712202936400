import React, { useEffect, useRef } from "react";
import HeatmapJS from "@mars3d/heatmap.js";
import lodash from "lodash";
import { IClickData } from '../../../models/Response';

type FigmaHeatmapProps = {
  width: number,
  height: number,
  clicks: IClickData[]
};

function FigmaHeatmap({ width, height, clicks }: FigmaHeatmapProps) {
  const heatmapRef = useRef<HTMLDivElement & { heatmapInstance: HeatmapJS.Heatmap<"value", "x", "y"> }>(null);


  useEffect(() => {
    if (!width || !height) return;
    if (!heatmapRef.current || !clicks) return;
    const clicksWeight: Record<string, number> = {};

    const heatmapInstance = !heatmapRef.current.heatmapInstance
      ? HeatmapJS.create({ container: heatmapRef.current })
      : heatmapRef.current.heatmapInstance;

    heatmapRef.current.heatmapInstance = heatmapInstance;

    clicks.forEach(({ clickData }) => {
      const x = lodash.round(clickData.left, -1);
      const y = lodash.round(clickData.top, -1);

      const key = `${x}${y}`;

      clicksWeight[key] = lodash.get(clicksWeight, key, 0) + 1;
    });

    const points = clicks.map(({ clickData }) => ({
      x: Math.floor(clickData.left),
      y: Math.floor(clickData.top),
      value: 1,
    }));

    heatmapInstance.setData({
      max: lodash.max(lodash.values(clicksWeight)) as number,
      min: 0,
      data: points
    });
  }, [width, height, clicks]);

  return (
    <div className='figma-heatmap' ref={heatmapRef} style={{ width, height }} />
  );
}

export default FigmaHeatmap;
