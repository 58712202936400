import React, { useState } from "react";
import clsx from "clsx";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { IconUp, IconDown } from "../../../icons";

import { SearchInput } from "../../Common";
import Tooltip from "../../Tooltip";

import { getShare } from "./utils";

type CardBlockProps = {
    id: string;
    value: string;
    description: string | null | undefined;
    categories: {
        id: string;
        value: string | undefined;
        count: number;
    }[];
    hiddenCategories: string[];
    totalResponses: number;
};

const CardBlock: React.FC<CardBlockProps> = ({ id, value, description, categories, hiddenCategories, totalResponses }) => {
    const { t } = useTranslation();
    const [showCategories, setShowCategories] = useState<boolean>(false);

    const showDescriptionTooltip = !!(description && description.length > 0);

    return (
      <div className="my-4">
        <div className="flex items-center justify-between w-full h-8 group">
          <div className="flex items-center">
            <Tooltip
              anchorClassName={clsx("font-medium block", showDescriptionTooltip ? "cursor-pointer" : "cursor-default")}
              content={<div className="w-48 text-xs">{description}</div>}
              showOnlyIf={showDescriptionTooltip}
            >
              <span className={clsx("font-medium block")}>{value}</span>
            </Tooltip>
          </div>
          <div
            className="cursor-pointer text-gray-700 flex item-center justify-center gap-1 hover:opacity-75 transition-all duration-75 ease-in"
            onClick={() => setShowCategories(!showCategories)}
          >
            <div className="flex items-center">
              {categories.length} {t("categories", { count: categories.length })}
            </div>
            {showCategories ? (
              <IconUp className="fill-current text-gray-600 w-6 h-6" />
            ) : (
              <IconDown className="fill-current text-gray-600 w-6 h-6" />
            )}
          </div>
        </div>
        {showCategories && (
          <div className="pt-4">
            {categories.map((category) => {
              if (hiddenCategories.includes(category.id)) return null;
              return (
                <div
                  key={category.id}
                  className="w-full flex justify-between items-center rounded-md px-[10px] py-[6px] my-2 bg-gray-100 relative"
                >
                  <div
                    className="absolute inset-0 bg-green-200 rounded-md"
                    style={{ width: `${getShare(category.count, totalResponses)}%` }}
                  ></div>
                  <div className="font-medium" style={{ zIndex: 1 }}>
                    {category.value}
                  </div>
                  <div className="flex items-center text-base" style={{ zIndex: 1 }}>
                    <span className="font-medium mr-2 text-gray-800">
                      {getShare(category.count, totalResponses) + "%"}
                    </span>
                    <span className="text-gray-600">({category.count})</span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
};


type ByCardProps = {
    blockId: string;
    cardsData: {
        [cardId: string]: {
            [categoryId: string]: number;
        };
    };
    cards: {
        id: string;
        value: string;
        description: string | null | undefined;
        image: string | null | undefined;
    }[];
    categories: {
        id: string;
        value: string | undefined;
    }[];
    hiddenCategories: string[];
    totalResponses: number;
};

const ByCard: React.FC<ByCardProps> = ({ blockId, cardsData, cards, categories, hiddenCategories, totalResponses }) => {

    const { t } = useTranslation();

    const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  return (
    <>
    <SearchInput id={`${blockId}-searchCards`} onChange={setSearchValue} placeholder={t("Search cards")} className="w-[240px]" />
    <div className="mt-6">
        {Object.entries(cardsData)
            .filter(([cardId]) => {
                const card = cards.find((card) => card.id === cardId);
                const cardName = card?.value;
                // Filter cards based on searchValue
                return !searchValue || (cardName && cardName.toLowerCase().includes(searchValue.toLowerCase()));
            })
            .map(([cardId, cardCategories], index, filteredCards) => {
                const card = cards.find((card) => card.id === cardId);
                const cardName = card?.value;
                const cardDescription = card?.description;
                const categoriesDataArray = Object.entries(cardCategories).map(([categoryId, count]) => {
                    const categoryName = categories.find((category) => category.id === categoryId)?.value;
                    return {
                        id: categoryId,
                        value: categoryName,
                        count: count,
                    }
                });
                return (
                    <React.Fragment key={cardId}>
                        <CardBlock id={cardId} value={cardName || ""} description={cardDescription} categories={categoriesDataArray} hiddenCategories={hiddenCategories} totalResponses={totalResponses} />
                        {index !== filteredCards.length - 1 && <hr className="border-t border-gray-200 my-4" />}
                    </React.Fragment>
                )
            })}
    </div>
</>
  )
};

export default ByCard;