export const FREE_TARIFF_CODE = 'free';
export const TEAM_TARIFF_CODE = 'team';
export const PROMO_TARIFF_CODE = 'promo';
export const TEAM_TARIFF_ID = (window.pwconfig?.teams_tariff_id) || 130;
export const CORPORATE_TARIFF_ID = 3;
export const ENTERPRISE_TARIFF_CODE = 'enterprise';

export const ROOT_FOLDER_ID = "root";

// Add constants at the top after imports
export const MIME_TYPES = {
	VIDEO_WEBM: 'video/webm',
	AUDIO_WEBM: 'audio/webm',
	/**
	 * Need to upload video with codecs for see video on Safari
	 */
	VIDEO_WEBM_CODECS_V8: 'video/webm; codecs=vp8',
	AUDIO_WEBM_CODECS_OPUS: 'audio/webm; codecs=opus',
	/**
	 * Safari can't record video in webm
	 */
	VIDEO_MP4: 'video/mp4',
	/**
	 * Safari can't record audio in webm
	 */
	AUDIO_MP4: 'audio/mp4',
	/**
	 * Firefox record video face with audio in webm
	 */
	VIDEO_WEBM_CODECS_V8_OPUS: 'video/webm; codecs=vp8, opus',
} as const;

export type MimeType = typeof MIME_TYPES[keyof typeof MIME_TYPES];


export const SESSION_STORAGE_KEYS = {
	ANSWER_ID: '_answer_id',
  ANSWER_CODE: '_answer_code',
  ANSWER_DEVICE_TYPE: '_answer_device_type',
  ANSWER_REQUIREMENTS_DEVICE: '_answer_requirements_device',
  LANGUAGE: 'language',
} as const;

export const LOCAL_STORAGE_KEYS = {
  ANSWER_RESP_ID: '_answer_resp_id',
	INITIAL_PROJECT_ID: 'initial_project_id',
} as const;
