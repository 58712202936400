import React, { useRef } from "react";
import clsx from "clsx";

import "../../tailwind.generated.css";

import { IconSearch } from "../../icons";

import Input from "../Input";

type SearchInputProps = {
  placeholder?: string;
  id: string;
  className?: string;
  onChange: (value: string) => void;
  value?: string;
  updateFromInitialValue?: boolean;
};

export default function SearchInput(props: SearchInputProps) {
  const searchWrapperRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      ref={searchWrapperRef}
      className={clsx(
        "default-input flex items-center justify-start",
        props.className && props.className
      )}
    >
      <IconSearch className=" flex-shrink-0 fill-current text-gray-500 mr-1" />
      <Input
        initialValue={props.value}
        className={clsx("w-full rounded-none")}
        placeholder={props.placeholder || "Search"}
        id={props.id}
        onChange={(value: string) => {
          props.onChange(value);
        }}
        onFocus={() => {
          if (searchWrapperRef.current) {
            searchWrapperRef.current?.classList.add("default-input-focus");
          }
        }}
        onBlur={() => {
          if (searchWrapperRef.current) {
            searchWrapperRef.current?.classList.remove("default-input-focus");
          }
        }}
        focusOnMount={false}
        selectAllOnFocus={false}
        hasClearIcon
        updateFromInitialValue={props.updateFromInitialValue}
      />
    </div>
  );
};
