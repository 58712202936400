interface IPercentageData {
  [key: string]: number;
}

/**
 * Calculates percentages for a set of values ensuring the sum equals 100%
 * @param data Object containing key-value pairs where values are counts
 * @param total Total sum of all values (optional, will be calculated if not provided)
 * @param fixed Number of decimal places (optional, if not provided numbers will be rounded to integers)
 * @returns Object with the same keys but values converted to percentages
 */
export function calculatePercentages(data: IPercentageData, total?: number, fixed = 0): IPercentageData {
  const percentages: IPercentageData = {};
  const keys = Object.keys(data);

  const totalCount = total ?? Object.values(data).reduce((sum, value) => sum + value, 0);

  if (totalCount === 0) {
    keys.forEach(key => {
      percentages[key] = 0;
    });
    return percentages;
  }

  // 1. Рассчитываем проценты с высокой точностью (до 10 знаков после запятой)
  const rawPercentages = keys.map(key => ({
    key,
    value: (data[key] / totalCount) * 100,
  }));

  // 2. Округляем значения, но считаем сумму без округления
  let totalRoundedPercent = 0;
  rawPercentages.forEach(entry => {
    percentages[entry.key] = Number(entry.value.toFixed(fixed));
    totalRoundedPercent += percentages[entry.key];
  });

  // 3. Корректируем последний элемент, чтобы сумма была ровно 100%
  const lastKey = rawPercentages[rawPercentages.length - 1]?.key;
  if (lastKey) {
    percentages[lastKey] += 100 - totalRoundedPercent;
  }

  return percentages;
}
