import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "../tailwind.generated.css";
import useIsMobile from "./Respondent/Pages/Test/hooks/useIsMobile";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useViewportUnits } from "./Common/Hooks/useViewportUnits";

type ModalProps = {
  isOpen: boolean;
  width: string;
  height?: string;
  setIsOpen: (isOpen: boolean) => void;
  background?: boolean;
  children: React.ReactNode;
  disallowClickOutside?: boolean;
  backgroundColor?: string;
  overlayColor?: string;
  noRoundedCorners?: boolean;
  noHeightLimit?: boolean;
  backdrop?: boolean;
  style?: React.CSSProperties;
  slow?: boolean;
  sidebarMode?: boolean; // New prop for sidebar mode
  fullScreen?: boolean;
  canFirePointerUpEvent?: boolean;
};

const Modal = ({
  isOpen,
  width,
  height,
  setIsOpen,
  background = true,
  children,
  disallowClickOutside = false,
  backgroundColor = "#FFFFFF",
  overlayColor = "rgba(30, 30, 30, 0.65)",
  noRoundedCorners = false,
  noHeightLimit = false,
  backdrop = false,
  style = {},
  slow = false,
  sidebarMode = false, // Default to false
  canFirePointerUpEvent = false,
  fullScreen = false,
}: ModalProps) => {
  const isMobile = useIsMobile();
  const viewportUnit = useViewportUnits();
  
  useEffect(() => {
    if (isOpen) {
      const overFlow = document.body.style.overflow;
      document.addEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "hidden";
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        document.body.style.overflow = overFlow;
      };
    }
  }, [isOpen]);

  function stopPropagation(e: React.PointerEvent<HTMLDivElement> | React.MouseEvent | MouseEvent | PointerEvent) {
    e.stopPropagation();
  }

  const handleClickOutside = (e: Event) => {
    if ((e.target as HTMLElement).classList.contains("modal-container") && !disallowClickOutside) {
      setIsOpen(false);
    }
  };

  const getContainerClasses = () => {
    if (isMobile) return "justify-center items-end";
    if (sidebarMode) return "justify-start items-stretch";
    return "justify-center items-center";
  };

  const getModalStyles = () => ({
    ...(background ? { backgroundColor } : {}),
    ...(isMobile ? { width: "100%", height: "auto" } : { width }),
    ...(height && !isMobile && { height }),
    ...(!height && !isMobile && !noHeightLimit && !sidebarMode && !fullScreen && { maxHeight: "90vh" }),
    ...(sidebarMode && !isMobile && { height: "100vh" }),
    ...(fullScreen && { width: "100dvw", height: `100${viewportUnit}`}),
    ...style,
  });

  const getAnimationProps = () => {
    if (isMobile) {
      return {
        initial: { y: "5%", opacity: 0 },
        animate: { y: 0, opacity: 1 },
        exit: { y: "5%", opacity: 0 },
      };
    }
    if (sidebarMode) {
      return {
        initial: { x: "-5%", opacity: 0 },
        animate: { x: 0, opacity: 1 },
        exit: { x: "-5%", opacity: 0 },
      };
    }
    return {
      initial: { scale: 0.97, opacity: 0 },
      animate: { scale: 1, opacity: 1 },
      exit: { scale: 0.97, opacity: 0 },
    };
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      className={clsx("modal-container", backdrop && "backdrop-blur-3xl", getContainerClasses())}
      style={{
        backgroundColor: overlayColor,
      }}
        onClick={stopPropagation}
        onPointerDown={stopPropagation}
        onPointerUp={(e)=> { !canFirePointerUpEvent && stopPropagation(e)}}
        onPointerMove={stopPropagation}
    >
      <AnimatePresence mode="wait">
        <motion.div
          {...getAnimationProps()}
          transition={{ duration: 0.2 }}
          data-test-id="modalDialog"
          className={clsx(
            slow ? "modal-dialog-slow" : "modal-dialog",
            noRoundedCorners ? "rounded-none" : isMobile ? "rounded-t-md" : sidebarMode ? "rounded-none" : "rounded-xl"
          )}
          style={getModalStyles()}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </div>,
    document.body
  );
};

export default Modal;