import React, { useState, useEffect } from "react";

type TimeDisplayProps = {
  videoRef: React.RefObject<HTMLVideoElement>;
};

export default function TimeDisplay(props: TimeDisplayProps) {
  const [currentTime, setCurrentTime] = useState(0);

  const duration = props.videoRef.current?.duration;

  useEffect(() => {
    const videoElement = props.videoRef.current;
    if (!videoElement) return;

    const updateTime = () => {
      setCurrentTime(videoElement.currentTime);
    };

    videoElement.addEventListener('timeupdate', updateTime);
    return () => videoElement.removeEventListener('timeupdate', updateTime);
  }, [props.videoRef]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className='video-player__time-display text-xs text-center text-white font-bold w-[40px]'>
      {formatTime(currentTime)}
    </div>
  );
};