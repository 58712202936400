import React, { useContext, useMemo } from "react";
import clsx from "clsx";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import "../../../tailwind.generated.css";

import { ScaleBlock } from "../../../models/Test";

import { getScaleRepliesList, findReplyNameById } from "../utils";
import { TestBlockReportContext } from "../ReportContext";
import ReportBlockHeader, { ReportHeaderMedia } from "../ReportBlockHeader";
import { calculatePercentages } from "../utils/calculatePercentages/calculatePercentages";
import { IconStar, IconStarFilled } from "../../../icons";
import { IconStarHalf } from "../../../icons";

const STAR_VALUES = [1, 2, 3, 4, 5];

interface IScaleResponse {
  selectedOption: string;
}

interface IScaleReportProps {
  responses: IScaleResponse[];
}

export default function ScaleReport(props: IScaleReportProps) {
  const { t } = useTranslation();

  const reportContext = useContext(TestBlockReportContext);
  const block = reportContext?.block as ScaleBlock;
  const { image, text, video } = block;

  const repliesList: { name: string | JSX.Element; value: string }[] =
    getScaleRepliesList(block);

  const data: any = {};
  const total = {
    totalSelectedOptions: 0,
  };

  total.totalSelectedOptions = 0;

  repliesList.forEach((reply) => {
    data[reply.value] = 0;
  });

  props.responses.forEach((response) => {
    if (!isNaN(data[response.selectedOption])) {
      data[response.selectedOption]++;
      total.totalSelectedOptions++;
    } else {
      return;
    }
  });

  // calculate average
  const average = () => {
    let sum = 0;
    let count = 0;

    Object.keys(data).forEach((key) => {
      sum += parseInt(key) * data[key];
      count += data[key];
    });
    
    return Math.round((sum / count) * 100) / 100;
  };

	const percentages = useMemo(() => {
		return calculatePercentages(data, props.responses.length);
	}, [data, props.responses.length]);

  return (
    <>
      <div className="scale-report__question">
        <span className="block caption">{t("Question")}</span>
        <ReportBlockHeader questionText={text} className="mb-4">
					<ReportHeaderMedia image={image} video={video} />
				</ReportBlockHeader>
      </div>
      {block.replyType === "numeric" && (
        <div>
          <span className="caption">{t("Average score")}</span>
          <div className="header3 py-2">{average()}</div>
        </div>
      )}
      {block.replyType === "stars" && (
        <div className="scale-report__stars-container">
          <span className="caption">{t("Average rating")}</span>
          <div className="flex items-center justify-start gap-2">
            <div className="header3 py-2">{average()}</div>
            <div className="scale-report__stars-visual flex items-center py-2">
              {STAR_VALUES.map((starValue) => {
                const avgValue = average();
                // Full star if the average is >= the star value
                // Half star if the average is within 0.5 of the star value
                const isFilled = avgValue >= starValue;
                const isHalfFilled = !isFilled && avgValue >= starValue - 0.5;
                const isEmpty = avgValue < starValue;
            
                return (
                  <div
                    key={starValue}
                    className={clsx(
                      "scale-report__star text-3xl mx-1",
                      isEmpty ? "text-gray-300" : "text-yellow-400"
                    )}
                  >
                    {isFilled ? <IconStarFilled className="fill-current" /> : isHalfFilled ? <IconStarHalf className="fill-current" /> : isEmpty ? <IconStar className="fill-current" /> : null}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      <div className="scale-report__plot flex flex-wrap gap-4 mt-8 w-full justify-between">
        {repliesList.map((reply) => (
          <div key={reply.value} className="scale-report__column w-1/6 min-w-[80px] max-w-[100px] mb-6 flex flex-col items-center justify-center">
            <div className="scale-report__bar bg-gray-200 rounded-md w-8 relative h-24">
              <div
                className="scale-report__bar-fill bg-yellow-300 w-8 rounded-md absolute bottom-0"
                style={{
                  height: `${percentages[reply.value]}%`,
                }}
              ></div>
            </div>
            <div className="scale-report__data mt-4 flex flex-col items-center">
              <div className={clsx(block.replyType === "emoji" && "text-2xl")}>
                {block.replyType === "stars" ? (
                  <div className="scale-report__reply-stars flex items-center gap-1 font-medium">
                    {findReplyNameById(reply.value, repliesList)}
                    <IconStarFilled width={16} height={16} className="text-yellow-300 fill-current" />
                  </div>
                ) : (
                  findReplyNameById(reply.value, repliesList)
                )}
              </div>
              <div className="scale-report__percentage font-medium mt-2">
                {percentages[reply.value] + "%"}
                <span className="scale-report__count font-normal text-gray-600">&nbsp;({data[reply.value]})</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};