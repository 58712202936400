import * as yup from 'yup';

export interface CreateChatRequestDTO {
	testId: string;
	answerId: string;
	blockId: string;
	isPreview?: boolean;
	previousBlocksAnswers?: Record<string, any | null>;
}

export const CreateChatRequestDTOSchema = yup.object<CreateChatRequestDTO>().shape({
	testId: yup.string().required(),
	answerId: yup.string().required(),
	blockId: yup.string().required(),
	isPreview: yup.boolean(),
	previousBlocksAnswers: yup.object().optional(),
});

export interface AddChatMessageRequestDTO {
	text: string;
	isPreview?: boolean;
}

export const AddChatMessageRequestDTOSchema = yup.object<AddChatMessageRequestDTO>().shape({
	text: yup.string().required(),
	isPreview: yup.boolean(),
});

export enum ChatStatus {
	Running = "running",
	Completed = "completed",
}

export interface ChatMessageDTO {
	id: number;
	message: MessageDTO | null;
	status: ChatStatus;
	reason: string | null;
}

export interface MessageDTO {
	text: string;
	assetIds?: string[];
}