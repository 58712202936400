import platform from "platform";
import { DeviceType } from "../../../models/Report";

export interface IDeviceInfo {
  deviceType: DeviceType | null;
  deviceOS: string | null;
  browser: string | null;
  viewportSize: string | null;
  screenSize: string | null;
}

export function getDeviceInfo(userAgent: string, screenSize?: string | null, viewportSize?: string | null ): IDeviceInfo {
  const parsedPlatform = platform.parse(userAgent);
  const deviceType = isMobileDevice(parsedPlatform) ? DeviceType.mobile : DeviceType.desktop;
  const browser = parsedPlatform.name || null;
  const deviceOS = getSimplifiedOS(parsedPlatform.os?.family);

  return { deviceType, deviceOS, browser, viewportSize: viewportSize || null, screenSize: screenSize || null };
}

function isMobileDevice(platform: any): boolean {
  const os = platform.os?.family?.toLowerCase();
  const ua = platform.ua?.toLowerCase();

  return (
    os?.includes("android") ||
    os?.includes("ios") ||
    os?.includes("iphone") ||
    os?.includes("mobile") ||
    ua?.includes("mobile") ||
    ua?.includes("android") ||
    ua?.includes("iphone")
  );
};

function getSimplifiedOS(osFamily?: string) {
  if (!osFamily) return null;

  if (/Windows/.test(osFamily)) {
    return 'Windows';
  } else if (/OS X|Mac OS/.test(osFamily)) {
    return 'Mac OS';
  } else if (/Linux/.test(osFamily)) {
    return 'Linux';
  } else if (/Android/.test(osFamily)) {
    return 'Android';
  } else if (/iOS|iPhone|iPad|iPod/.test(osFamily)) {
    return 'iOS';
  } else {
    return osFamily;
  }
}

export function isYandexBrowser(userAgent: string) {
  const parsedPlatform = platform.parse(userAgent);
  const browserName = parsedPlatform.name?.toLowerCase();
  return browserName?.includes('yandex');
}

export function isFirefoxBrowser(userAgent: string) {
  const parsedPlatform = platform.parse(userAgent);
  const browserName = parsedPlatform.name?.toLowerCase();
  return browserName?.includes('firefox');
}

export function isChromeBrowser(userAgent: string) {
  const parsedPlatform = platform.parse(userAgent);
  const browserName = parsedPlatform.name?.toLowerCase();
  return browserName?.includes('chrome');
}
