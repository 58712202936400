import * as Sentry from '@sentry/react';

interface LoggerService {
  /**
    * tagType is used for classifying log messages in Alerts, 
    * which makes it easier to filter and analyze logs.
    */
  logWarning(message: string, tagType: string): void;
  logError(message: string, tagType: string): void;
}


export class SentryLogger implements LoggerService {
  logWarning(message: string, tagType: string, extra?: Record<string, any>) {
    Sentry.captureMessage(message,
      {
        level: 'warning',
        tags: {
          type: tagType,
        },
        extra,
      }
    );
  }

  logError(message: string, tagType: string, extra?: Record<string, any>) {
    Sentry.captureMessage(message,
      {
        level: 'error',
        tags: {
          type: tagType,
        },
        extra,
      }
    );
  }
}