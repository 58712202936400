import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import "../../../tailwind.generated.css";

import ImageWithZoom from "../ImageWithZoom";
import ReportBlockHeader from "../ReportBlockHeader";

import { IPreferenceResponse } from "../../../models/Response";
import { IPreferenceOptionReply } from "../../../models/Test";
import { calculatePercentages } from "../utils/calculatePercentages/calculatePercentages";

interface ISingleReportProps {
  replies: IPreferenceOptionReply[];
  responses: IPreferenceResponse[];
  questionText: string;
}

export default function SingleReport(props: ISingleReportProps) {
  const { t } = useTranslation();

  const findReplyDataById = (id: string) => {
    const reply = props.replies.find((element) => element.id === id);
    return { replyValue: reply?.replyValue, image: reply?.image };
  };

  const data: Record<string, number> = {};
  const total: Record<string, number> = {};

  total.totalSelectedOptions = 0;

  props.replies.forEach((reply) => {
    data[reply.id] = 0;
  });

  props.responses.forEach((response) => {
    response.selectedOptions.forEach((option) => {
      if (typeof option === "object" && "id" in option && !isNaN(data[option.id])) {
        data[option.id]++;
        total.totalSelectedOptions++;
      } else {
        return;
      }
    });
  });

  const percentages = useMemo(() => {
    return calculatePercentages(data, total.totalSelectedOptions);
  }, [data, total.totalSelectedOptions]);

  return (
    <div className="single-report flex flex-col gap-4">
      <div className="single-report__question">
        <span className="block caption">{t("Question")} </span>
        <ReportBlockHeader questionText={props.questionText} className="mb-4" />
      </div>
      {Object.keys(data).map((key) => (
        <div key={`single-icon-${key}`} className="single-report__item">
          <div className="single-report__item-container flex justify-between w-full items-center">
            <div className="single-report__image-wrapper flex items-center justify-center">
              <div className="single-report__image-container singleOption relative">
                <ImageWithZoom
                  imageSrc={findReplyDataById(key).image}
                  className="single-report__image rounded-md w-12 h-12 object-contain bg-gray-200"
                />
              </div>
            </div>
            <div className="single-report__content flex-1 pl-6 mt-1">
              <div className="single-report__progress h-1 bg-gray-300 rounded-full w-full relative">
                <div
                  className="single-report__progress-bar bg-purple-600 h-1 rounded-full"
                  style={{
                    width: `${percentages[key]}%`,
                  }}
                ></div>
              </div>
              <div className="single-report__details flex justify-between items-center mt-2">
                <div className="single-report__value font-medium">
                  {findReplyDataById(key).replyValue}
                </div>
                <div className="single-report__percentage font-medium">
                  {percentages[key] + "%"}
                  <span className="single-report__count font-normal text-gray-600">
                    &nbsp;({data[key]})
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
